import React from "react";

const SortArrows = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4697 2.46967C11.7626 2.17678 12.2374 2.17678 12.5303 2.46967L17.5303 7.46967C17.8232 7.76256 17.8232 8.23744 17.5303 8.53033C17.2374 8.82322 16.7626 8.82322 16.4697 8.53033L12 4.06066L7.53033 8.53033C7.23744 8.82322 6.76256 8.82322 6.46967 8.53033C6.17678 8.23744 6.17678 7.76256 6.46967 7.46967L11.4697 2.46967Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4697 21.5303C11.7626 21.8232 12.2374 21.8232 12.5303 21.5303L17.5303 16.5303C17.8232 16.2374 17.8232 15.7626 17.5303 15.4697C17.2374 15.1768 16.7626 15.1768 16.4697 15.4697L12 19.9393L7.53033 15.4697C7.23744 15.1768 6.76256 15.1768 6.46967 15.4697C6.17678 15.7626 6.17678 16.2374 6.46967 16.5303L11.4697 21.5303Z"
      fill="currentColor"
    />
  </svg>
);

export default SortArrows;
