import React from "react";

const ServiceIconHojalateriaPintura = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M5.37905 14.25H3.54869C3.4516 14.25 3.35849 14.2105 3.28984 14.1402C3.22119 14.0698 3.18262 13.9745 3.18262 13.875V9.75C3.18262 9.65054 3.22119 9.55516 3.28984 9.48483C3.35849 9.41451 3.4516 9.375 3.54869 9.375H5.37905C5.47613 9.375 5.56925 9.41451 5.6379 9.48483C5.70655 9.55516 5.74512 9.65054 5.74512 9.75V13.875C5.74512 13.9745 5.70655 14.0698 5.6379 14.1402C5.56925 14.2105 5.47613 14.25 5.37905 14.25ZM3.91476 13.5H5.01297V10.125H3.91476V13.5Z"
      fill="currentColor"
    />
    <path
      d="M16.7278 14.25H15.2635C15.1664 14.25 15.0733 14.2105 15.0047 14.1402C14.936 14.0698 14.8975 13.9745 14.8975 13.875V9.75C14.8975 9.65054 14.936 9.55516 15.0047 9.48483C15.0733 9.41451 15.1664 9.375 15.2635 9.375H16.7278C16.8249 9.375 16.918 9.41451 16.9867 9.48483C17.0553 9.55516 17.0939 9.65054 17.0939 9.75V13.875C17.0939 13.9745 17.0553 14.0698 16.9867 14.1402C16.918 14.2105 16.8249 14.25 16.7278 14.25ZM15.6296 13.5H16.3617V10.125H15.6296V13.5Z"
      fill="currentColor"
    />
    <path
      d="M16.725 13.8739C16.6564 13.8741 16.5892 13.8546 16.5309 13.8176C16.4782 13.7838 16.4347 13.7368 16.4046 13.6811C16.3745 13.6253 16.3588 13.5626 16.3589 13.4989V10.1239C16.3591 10.0599 16.3752 9.99713 16.4058 9.94139C16.4363 9.88565 16.4803 9.83884 16.5335 9.8054C16.5866 9.77196 16.6473 9.753 16.7096 9.75033C16.772 9.74765 16.834 9.76135 16.8897 9.79012L19.0861 10.9151C19.1465 10.9463 19.1973 10.994 19.2328 11.0529C19.2683 11.1118 19.2873 11.1796 19.2875 11.2489V12.3739C19.2873 12.4431 19.2683 12.511 19.2328 12.5699C19.1973 12.6288 19.1465 12.6765 19.0861 12.7076L16.8897 13.8326C16.8387 13.8594 16.7823 13.8735 16.725 13.8739ZM17.091 10.7314V12.8914L18.5553 12.1414V11.4814L17.091 10.7314Z"
      fill="currentColor"
    />
    <path
      d="M15.2637 13.5H5.37974C5.28266 13.5 5.18954 13.4605 5.12089 13.3902C5.05224 13.3198 5.01367 13.2245 5.01367 13.125V10.5C5.01367 10.4005 5.05224 10.3052 5.12089 10.2348C5.18954 10.1645 5.28266 10.125 5.37974 10.125H15.2637C15.3608 10.125 15.4539 10.1645 15.5225 10.2348C15.5912 10.3052 15.6297 10.4005 15.6297 10.5V13.125C15.6297 13.2245 15.5912 13.3198 15.5225 13.3902C15.4539 13.4605 15.3608 13.5 15.2637 13.5ZM5.74581 12.75H14.8976V10.875H5.74581V12.75Z"
      fill="currentColor"
    />
    <path
      d="M8.67362 21.7501H6.38568C6.16639 21.7494 5.95004 21.6983 5.75262 21.6005C5.5552 21.5027 5.38174 21.3608 5.24505 21.1851C5.10836 21.0095 5.01193 20.8046 4.96288 20.5856C4.91383 20.3667 4.91341 20.1392 4.96166 19.9201L6.47719 13.0426C6.49579 12.958 6.54243 12.8826 6.60918 12.8293C6.67592 12.776 6.75864 12.748 6.84327 12.7501H10.504C10.5596 12.7487 10.6149 12.7603 10.6655 12.7841C10.7161 12.8079 10.7608 12.8432 10.7961 12.8873C10.8314 12.9314 10.8564 12.9832 10.8692 13.0387C10.882 13.0942 10.8823 13.152 10.8701 13.2076L9.03969 21.4576C9.0211 21.5422 8.97446 21.6176 8.90771 21.6709C8.84097 21.7242 8.75824 21.7522 8.67362 21.7501ZM7.13612 13.5001L5.67184 20.0851C5.64768 20.1948 5.64793 20.3087 5.67255 20.4183C5.69718 20.5279 5.74556 20.6305 5.81411 20.7183C5.88265 20.8062 5.96962 20.8772 6.06856 20.9259C6.1675 20.9747 6.27588 21.0001 6.38568 21.0001H8.38077L10.0464 13.5001H7.13612Z"
      fill="currentColor"
    />
    <path
      d="M11.9684 16.875H9.77193C9.71724 16.8752 9.6632 16.8628 9.61379 16.8388C9.56438 16.8148 9.52085 16.7798 9.48639 16.7363C9.45145 16.6923 9.42671 16.6407 9.41403 16.5855C9.40135 16.5303 9.40106 16.4728 9.41318 16.4175L10.1453 13.0425C10.1637 12.9592 10.2092 12.8848 10.2745 12.8316C10.3397 12.7784 10.4207 12.7496 10.5041 12.75H11.9684C12.0654 12.75 12.1586 12.7895 12.2272 12.8598C12.2959 12.9302 12.3344 13.0255 12.3344 13.125V16.5C12.3344 16.5995 12.2959 16.6948 12.2272 16.7652C12.1586 16.8355 12.0654 16.875 11.9684 16.875ZM10.2295 16.125H11.6023V13.5H10.7969L10.2295 16.125Z"
      fill="currentColor"
    />
    <path
      d="M11.2337 10.875H9.76939C9.6723 10.875 9.57919 10.8355 9.51054 10.7652C9.44189 10.6948 9.40332 10.5995 9.40332 10.5V9.9525C9.40278 9.83351 9.37462 9.71636 9.32115 9.61073C9.26768 9.5051 9.19044 9.41401 9.09582 9.345L7.45216 8.14125C7.26309 8.00245 7.10901 7.8195 7.0027 7.60756C6.89639 7.39562 6.8409 7.16078 6.84082 6.9225V2.625C6.84082 2.52554 6.87939 2.43016 6.94804 2.35984C7.01669 2.28951 7.1098 2.25 7.20689 2.25H13.7962C13.8933 2.25 13.9864 2.28951 14.055 2.35984C14.1237 2.43016 14.1622 2.52554 14.1622 2.625V6.9225C14.1622 7.16078 14.1067 7.39562 14.0004 7.60756C13.8941 7.8195 13.74 8.00245 13.5509 8.14125L11.9072 9.345C11.8126 9.41401 11.7354 9.5051 11.6819 9.61073C11.6285 9.71636 11.6003 9.83351 11.5997 9.9525V10.5C11.5997 10.5995 11.5612 10.6948 11.4925 10.7652C11.4239 10.8355 11.3308 10.875 11.2337 10.875ZM10.1355 10.125H10.8676V9.9525C10.8677 9.71422 10.9232 9.47938 11.0295 9.26744C11.1358 9.0555 11.2899 8.87255 11.4789 8.73375L13.1226 7.53C13.2172 7.46099 13.2945 7.3699 13.3479 7.26427C13.4014 7.15864 13.4296 7.04149 13.4301 6.9225V3H7.57296V6.9225C7.5735 7.04149 7.60167 7.15864 7.65514 7.26427C7.7086 7.3699 7.78584 7.46099 7.88046 7.53L9.52412 8.73375C9.7132 8.87255 9.86727 9.0555 9.97358 9.26744C10.0799 9.47938 10.1354 9.71422 10.1355 9.9525V10.125Z"
      fill="currentColor"
    />
    <path
      d="M21.1188 12.1875H19.9803C19.8832 12.1875 19.7901 12.148 19.7215 12.0777C19.6528 12.0073 19.6143 11.912 19.6143 11.8125C19.6143 11.713 19.6528 11.6177 19.7215 11.5473C19.7901 11.477 19.8832 11.4375 19.9803 11.4375H21.1188C21.2159 11.4375 21.309 11.477 21.3777 11.5473C21.4463 11.6177 21.4849 11.713 21.4849 11.8125C21.4849 11.912 21.4463 12.0073 21.3777 12.0777C21.309 12.148 21.2159 12.1875 21.1188 12.1875Z"
      fill="currentColor"
    />
    <path
      d="M19.9794 11.0615C19.9004 11.0613 19.8237 11.035 19.7606 10.9864C19.6974 10.9379 19.6513 10.8697 19.6291 10.7921C19.6068 10.7145 19.6097 10.6317 19.6372 10.5559C19.6647 10.4801 19.7154 10.4154 19.7817 10.3715L20.9202 9.62154C21.0016 9.57396 21.0977 9.56019 21.1888 9.58306C21.2798 9.60594 21.3588 9.66371 21.4095 9.74448C21.4602 9.82525 21.4787 9.92287 21.4612 10.0172C21.4437 10.1115 21.3916 10.1954 21.3155 10.2515L20.1771 11.0015C20.1181 11.0405 20.0495 11.0614 19.9794 11.0615Z"
      fill="currentColor"
    />
    <path
      d="M21.1172 14.0635C21.047 14.0633 20.9784 14.0425 20.9195 14.0035L19.781 13.2535C19.705 13.1974 19.6528 13.1135 19.6353 13.0192C19.6178 12.9248 19.6364 12.8272 19.687 12.7464C19.7377 12.6657 19.8167 12.6079 19.9078 12.585C19.9988 12.5621 20.0949 12.5759 20.1764 12.6235L21.3148 13.3735C21.3963 13.4273 21.4536 13.512 21.4742 13.609C21.4948 13.706 21.477 13.8074 21.4247 13.891C21.3916 13.9438 21.3461 13.9873 21.2924 14.0174C21.2387 14.0475 21.1784 14.0634 21.1172 14.0635Z"
      fill="currentColor"
    />
    <path
      d="M22.2181 9.75C22.4203 9.75 22.5842 9.58211 22.5842 9.375C22.5842 9.16789 22.4203 9 22.2181 9C22.0159 9 21.8521 9.16789 21.8521 9.375C21.8521 9.58211 22.0159 9.75 22.2181 9.75Z"
      fill="currentColor"
    />
    <path
      d="M22.2181 14.625C22.4203 14.625 22.5842 14.4571 22.5842 14.25C22.5842 14.0429 22.4203 13.875 22.2181 13.875C22.0159 13.875 21.8521 14.0429 21.8521 14.25C21.8521 14.4571 22.0159 14.625 22.2181 14.625Z"
      fill="currentColor"
    />
    <path
      d="M22.2181 12.1875C22.4203 12.1875 22.5842 12.0196 22.5842 11.8125C22.5842 11.6054 22.4203 11.4375 22.2181 11.4375C22.0159 11.4375 21.8521 11.6054 21.8521 11.8125C21.8521 12.0196 22.0159 12.1875 22.2181 12.1875Z"
      fill="currentColor"
    />
    <path
      d="M13.7962 4.875H7.20689C7.1098 4.875 7.01669 4.83549 6.94804 4.76517C6.87939 4.69484 6.84082 4.59946 6.84082 4.5C6.84082 4.40054 6.87939 4.30516 6.94804 4.23484C7.01669 4.16451 7.1098 4.125 7.20689 4.125H13.7962C13.8933 4.125 13.9864 4.16451 14.055 4.23484C14.1237 4.30516 14.1622 4.40054 14.1622 4.5C14.1622 4.59946 14.1237 4.69484 14.055 4.76517C13.9864 4.83549 13.8933 4.875 13.7962 4.875Z"
      fill="currentColor"
    />
  </svg>
);

export default ServiceIconHojalateriaPintura;
