import React from "react";

const ServiceIconMotocicletas = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M12.6673 22.2305C12.1818 22.2305 11.7163 22.0329 11.373 21.6813C11.0298 21.3297 10.8369 20.8528 10.8369 20.3555V14.3555C10.8369 13.8582 11.0298 13.3813 11.373 13.0296C11.7163 12.678 12.1818 12.4805 12.6673 12.4805C13.1527 12.4805 13.6183 12.678 13.9615 13.0296C14.3048 13.3813 14.4976 13.8582 14.4976 14.3555V20.3555C14.4976 20.8528 14.3048 21.3297 13.9615 21.6813C13.6183 22.0329 13.1527 22.2305 12.6673 22.2305ZM12.6673 13.2305C12.376 13.2305 12.0967 13.349 11.8907 13.56C11.6848 13.771 11.5691 14.0571 11.5691 14.3555V20.3555C11.5691 20.6538 11.6848 20.94 11.8907 21.151C12.0967 21.3619 12.376 21.4805 12.6673 21.4805C12.9585 21.4805 13.2379 21.3619 13.4438 21.151C13.6498 20.94 13.7655 20.6538 13.7655 20.3555V14.3555C13.7655 14.0571 13.6498 13.771 13.4438 13.56C13.2379 13.349 12.9585 13.2305 12.6673 13.2305Z"
      fill="currentColor"
    />
    <path
      d="M15.2294 14.3535C15.1323 14.3535 15.0392 14.314 14.9705 14.2437C14.9018 14.1734 14.8633 14.078 14.8633 13.9785C14.8633 12.9435 13.8785 12.1035 12.6669 12.1035C11.4552 12.1035 10.4704 12.9435 10.4704 13.9785C10.4704 14.078 10.4319 14.1734 10.3632 14.2437C10.2946 14.314 10.2014 14.3535 10.1044 14.3535C10.0073 14.3535 9.91415 14.314 9.8455 14.2437C9.77685 14.1734 9.73828 14.078 9.73828 13.9785C9.73828 12.531 11.0525 11.3535 12.6669 11.3535C14.2812 11.3535 15.5954 12.531 15.5954 13.9785C15.5954 14.078 15.5569 14.1734 15.4882 14.2437C15.4196 14.314 15.3264 14.3535 15.2294 14.3535Z"
      fill="currentColor"
    />
    <path
      d="M10.1038 18.48H8.63951C8.54242 18.48 8.44931 18.4405 8.38066 18.3702C8.31201 18.2998 8.27344 18.2045 8.27344 18.105V13.0313C8.27344 12.9318 8.31201 12.8364 8.38066 12.7661C8.44931 12.6958 8.54242 12.6562 8.63951 12.6562H10.1038C10.2009 12.6562 10.294 12.6958 10.3626 12.7661C10.4313 12.8364 10.4699 12.9318 10.4699 13.0313V18.105C10.4699 18.2045 10.4313 18.2998 10.3626 18.3702C10.294 18.4405 10.2009 18.48 10.1038 18.48ZM9.00558 17.73H9.73772V13.4063H9.00558V17.73Z"
      fill="currentColor"
    />
    <path
      d="M10.1039 13.4067H9.00572C8.90863 13.4067 8.81552 13.3672 8.74687 13.2969C8.67822 13.2266 8.63965 13.1312 8.63965 13.0317V10.6055C8.63965 10.506 8.67822 10.4106 8.74687 10.3403C8.81552 10.27 8.90863 10.2305 9.00572 10.2305H10.1039C10.201 10.2305 10.2941 10.27 10.3628 10.3403C10.4314 10.4106 10.47 10.506 10.47 10.6055V13.0317C10.47 13.1312 10.4314 13.2266 10.3628 13.2969C10.2941 13.3672 10.201 13.4067 10.1039 13.4067ZM9.37179 12.6567H9.73786V10.9805H9.37179V12.6567Z"
      fill="currentColor"
    />
    <path
      d="M16.3295 13.4067H15.2313C15.1342 13.4067 15.0411 13.3672 14.9725 13.2969C14.9038 13.2266 14.8652 13.1312 14.8652 13.0317V10.6055C14.8652 10.506 14.9038 10.4106 14.9725 10.3403C15.0411 10.27 15.1342 10.2305 15.2313 10.2305H16.3295C16.4266 10.2305 16.5197 10.27 16.5884 10.3403C16.657 10.4106 16.6956 10.506 16.6956 10.6055V13.0317C16.6956 13.1312 16.657 13.2266 16.5884 13.2969C16.5197 13.3672 16.4266 13.4067 16.3295 13.4067ZM15.5974 12.6567H15.9634V10.9805H15.5974V12.6567Z"
      fill="currentColor"
    />
    <path
      d="M16.6956 18.4805H15.2313C15.1342 18.4805 15.0411 18.441 14.9725 18.3706C14.9038 18.3003 14.8652 18.2049 14.8652 18.1055V12.8555C14.8652 12.756 14.9038 12.6606 14.9725 12.5903C15.0411 12.52 15.1342 12.4805 15.2313 12.4805H16.6956C16.7927 12.4805 16.8858 12.52 16.9544 12.5903C17.0231 12.6606 17.0617 12.756 17.0617 12.8555V18.1055C17.0617 18.2049 17.0231 18.3003 16.9544 18.3706C16.8858 18.441 16.7927 18.4805 16.6956 18.4805ZM15.5974 17.7305H16.3295V13.2305H15.5974V17.7305Z"
      fill="currentColor"
    />
    <path
      d="M12.6671 10.2285C12.2327 10.2285 11.8081 10.0966 11.4469 9.84932C11.0857 9.60209 10.8041 9.25069 10.6379 8.83955C10.4717 8.42842 10.4282 7.97602 10.5129 7.53956C10.5977 7.10311 10.8068 6.70219 11.114 6.38753C11.4212 6.07286 11.8126 5.85857 12.2386 5.77175C12.6647 5.68493 13.1063 5.72949 13.5077 5.89979C13.909 6.07008 14.252 6.35847 14.4934 6.72848C14.7347 7.09849 14.8636 7.53351 14.8636 7.97852C14.8636 8.57525 14.6322 9.14755 14.2202 9.56951C13.8083 9.99146 13.2497 10.2285 12.6671 10.2285ZM12.6671 6.47852C12.3775 6.47852 12.0944 6.56649 11.8536 6.73131C11.6128 6.89613 11.4251 7.1304 11.3143 7.40449C11.2035 7.67858 11.1745 7.98018 11.231 8.27115C11.2875 8.56212 11.4269 8.8294 11.6317 9.03918C11.8365 9.24896 12.0974 9.39182 12.3815 9.44969C12.6655 9.50757 12.9599 9.47787 13.2275 9.36434C13.4951 9.2508 13.7237 9.05855 13.8846 8.81187C14.0455 8.5652 14.1314 8.27519 14.1314 7.97852C14.1314 7.58069 13.9771 7.19916 13.7025 6.91786C13.4279 6.63655 13.0555 6.47852 12.6671 6.47852Z"
      fill="currentColor"
    />
    <path
      d="M17.9736 9.48047C17.7202 9.48047 17.4725 9.40349 17.2618 9.25927C17.0511 9.11505 16.8869 8.91007 16.7899 8.67024C16.6929 8.43041 16.6676 8.16651 16.717 7.91191C16.7664 7.65731 16.8885 7.42345 17.0677 7.23989C17.2468 7.05634 17.4751 6.93133 17.7237 6.88069C17.9722 6.83005 18.2298 6.85604 18.4639 6.95538C18.6981 7.05472 18.8982 7.22294 19.039 7.43878C19.1797 7.65462 19.2549 7.90838 19.2549 8.16797C19.2549 8.51607 19.1199 8.84991 18.8796 9.09605C18.6393 9.34219 18.3134 9.48047 17.9736 9.48047ZM17.9736 7.60547C17.865 7.60547 17.7589 7.63846 17.6686 7.70027C17.5783 7.76208 17.5079 7.84993 17.4663 7.95271C17.4248 8.05549 17.4139 8.16859 17.4351 8.27771C17.4563 8.38682 17.5086 8.48705 17.5854 8.56572C17.6621 8.64438 17.76 8.69796 17.8665 8.71966C17.973 8.74137 18.0834 8.73023 18.1838 8.68765C18.2841 8.64508 18.3699 8.57298 18.4302 8.48048C18.4905 8.38797 18.5227 8.27922 18.5227 8.16797C18.5227 8.01879 18.4649 7.87571 18.3619 7.77022C18.2589 7.66473 18.1193 7.60547 17.9736 7.60547Z"
      fill="currentColor"
    />
    <path
      d="M7.35742 9.48047C7.10402 9.48047 6.8563 9.40349 6.6456 9.25927C6.4349 9.11505 6.27068 8.91007 6.1737 8.67024C6.07673 8.43041 6.05135 8.16651 6.10079 7.91191C6.15023 7.65731 6.27226 7.42345 6.45144 7.23989C6.63063 7.05634 6.85893 6.93133 7.10746 6.88069C7.356 6.83005 7.61362 6.85604 7.84774 6.95538C8.08185 7.05472 8.28196 7.22294 8.42274 7.43878C8.56353 7.65462 8.63867 7.90838 8.63867 8.16797C8.63867 8.51607 8.50368 8.84991 8.2634 9.09605C8.02312 9.34219 7.69723 9.48047 7.35742 9.48047ZM7.35742 7.60547C7.24882 7.60547 7.14266 7.63846 7.05236 7.70027C6.96205 7.76208 6.89167 7.84993 6.85011 7.95271C6.80855 8.05549 6.79768 8.16859 6.81887 8.27771C6.84005 8.38682 6.89235 8.48705 6.96915 8.56572C7.04594 8.64438 7.14378 8.69796 7.2503 8.71966C7.35681 8.74137 7.46722 8.73023 7.56756 8.68765C7.66789 8.64508 7.75365 8.57298 7.81399 8.48048C7.87432 8.38797 7.90653 8.27922 7.90653 8.16797C7.90653 8.01879 7.84868 7.87571 7.7457 7.77022C7.64272 7.66473 7.50305 7.60547 7.35742 7.60547Z"
      fill="currentColor"
    />
    <path
      d="M15.2309 17.6504H14.1327C14.0356 17.6504 13.9425 17.6109 13.8738 17.5406C13.8052 17.4702 13.7666 17.3748 13.7666 17.2754C13.7666 17.1759 13.8052 17.0806 13.8738 17.0102C13.9425 16.9399 14.0356 16.9004 14.1327 16.9004H15.2309C15.328 16.9004 15.4211 16.9399 15.4897 17.0102C15.5584 17.0806 15.597 17.1759 15.597 17.2754C15.597 17.3748 15.5584 17.4702 15.4897 17.5406C15.4211 17.6109 15.328 17.6504 15.2309 17.6504Z"
      fill="currentColor"
    />
    <path
      d="M11.2026 17.7285H10.1044C10.0073 17.7285 9.91415 17.689 9.8455 17.6187C9.77685 17.5484 9.73828 17.453 9.73828 17.3535C9.73828 17.2541 9.77685 17.1587 9.8455 17.0884C9.91415 17.018 10.0073 16.9785 10.1044 16.9785H11.2026C11.2997 16.9785 11.3928 17.018 11.4614 17.0884C11.5301 17.1587 11.5686 17.2541 11.5686 17.3535C11.5686 17.453 11.5301 17.5484 11.4614 17.6187C11.3928 17.689 11.2997 17.7285 11.2026 17.7285Z"
      fill="currentColor"
    />
    <path
      d="M17.059 10.9805H8.2733C8.17621 10.9805 8.0831 10.941 8.01445 10.8706C7.94579 10.8003 7.90723 10.7049 7.90723 10.6055V7.02422C7.90819 6.48249 8.1187 5.96323 8.49264 5.58016C8.86659 5.1971 9.37348 4.98146 9.90232 4.98047H15.43C15.9588 4.98146 16.4657 5.1971 16.8397 5.58016C17.2136 5.96323 17.4241 6.48249 17.4251 7.02422V10.6055C17.4251 10.7049 17.3865 10.8003 17.3179 10.8706C17.2492 10.941 17.1561 10.9805 17.059 10.9805ZM8.63937 10.2305H16.6929V7.02422C16.6929 6.68109 16.5599 6.35202 16.323 6.1094C16.0862 5.86677 15.7649 5.73047 15.43 5.73047H9.90232C9.56736 5.73047 9.24613 5.86677 9.00928 6.1094C8.77243 6.35202 8.63937 6.68109 8.63937 7.02422V10.2305Z"
      fill="currentColor"
    />
    <path
      d="M10.1222 5.74547C10.0339 5.74649 9.94821 5.7148 9.88094 5.65622C9.81367 5.59765 9.76933 5.51614 9.75609 5.42672C9.74026 5.34139 9.73168 5.25482 9.73047 5.16797C9.73047 4.72042 9.90403 4.29119 10.213 3.97473C10.5219 3.65826 10.9409 3.48047 11.3778 3.48047C11.8147 3.48047 12.2337 3.65826 12.5426 3.97473C12.8516 4.29119 13.0251 4.72042 13.0251 5.16797C13.0234 5.24468 13.0161 5.32114 13.0031 5.39672C12.9932 5.48886 12.9503 5.57397 12.8827 5.6356C12.8151 5.69723 12.7276 5.73103 12.6371 5.73047H10.2247H10.1551L10.1222 5.74547ZM10.4882 4.99547H12.3003C12.2583 4.78354 12.146 4.593 11.9826 4.45615C11.8191 4.31929 11.6145 4.24453 11.4034 4.24453C11.1923 4.24453 10.9877 4.31929 10.8242 4.45615C10.6608 4.593 10.5485 4.78354 10.5065 4.99547H10.4882Z"
      fill="currentColor"
    />
    <path
      d="M15.2301 5.74609H15.1971H15.1276H12.6859C12.5971 5.74699 12.5109 5.71477 12.4436 5.65543C12.3762 5.5961 12.3322 5.5137 12.3198 5.42359C12.3074 5.34417 12.3001 5.264 12.2979 5.18359C12.2979 4.73604 12.4714 4.30682 12.7803 3.99035C13.0893 3.67388 13.5083 3.49609 13.9452 3.49609C14.3821 3.49609 14.8011 3.67388 15.11 3.99035C15.4189 4.30682 15.5925 4.73604 15.5925 5.18359C15.5913 5.27044 15.5827 5.35702 15.5669 5.44234C15.5516 5.52408 15.5102 5.59828 15.4492 5.65328C15.3883 5.70827 15.3112 5.74092 15.2301 5.74609ZM13.0703 4.99609H14.864C14.822 4.78416 14.7098 4.59363 14.5463 4.45677C14.3829 4.31992 14.1782 4.24515 13.9671 4.24515C13.756 4.24515 13.5514 4.31992 13.388 4.45677C13.2245 4.59363 13.1123 4.78416 13.0703 4.99609Z"
      fill="currentColor"
    />
    <path
      d="M10.1028 5.72984C10.0202 5.72935 9.94024 5.70028 9.87583 5.64734L7.24012 3.49484L4.82039 3.67859C4.72383 3.68583 4.62841 3.65359 4.555 3.58894C4.4816 3.52428 4.43618 3.43247 4.42869 3.33359C4.42521 3.28432 4.43126 3.23483 4.4465 3.18796C4.46174 3.14109 4.48588 3.09777 4.51751 3.06049C4.54915 3.0232 4.58766 2.99269 4.63084 2.9707C4.67403 2.9487 4.72102 2.93567 4.76914 2.93234L7.33164 2.73734C7.42247 2.73049 7.51252 2.75857 7.58423 2.81609L10.3298 5.06609C10.3873 5.11495 10.4289 5.18069 10.449 5.25449C10.469 5.32828 10.4665 5.40659 10.4418 5.47887C10.417 5.55116 10.3713 5.61396 10.3107 5.65882C10.2501 5.70369 10.1776 5.72847 10.1028 5.72984Z"
      fill="currentColor"
    />
    <path
      d="M5.71317 4.97945C5.64964 4.97944 5.5872 4.96249 5.53201 4.93027C5.47681 4.89806 5.43074 4.85168 5.39834 4.7957C5.34884 4.71016 5.33453 4.60799 5.35856 4.51165C5.38259 4.41531 5.44298 4.33268 5.52647 4.28195L7.48129 3.0782C7.56387 3.03997 7.65736 3.03447 7.74367 3.06278C7.82998 3.09109 7.90297 3.15118 7.94852 3.23144C7.99407 3.3117 8.00894 3.40641 7.99026 3.49725C7.97157 3.58809 7.92065 3.6686 7.84736 3.7232L5.88888 4.9232C5.83594 4.95698 5.77546 4.97634 5.71317 4.97945Z"
      fill="currentColor"
    />
    <path
      d="M15.2299 5.72953C15.1534 5.73007 15.0786 5.70603 15.0161 5.66078C14.9536 5.61553 14.9065 5.55135 14.8815 5.47727C14.8564 5.40319 14.8547 5.32293 14.8765 5.24777C14.8983 5.17261 14.9425 5.10634 15.003 5.05828L17.7485 2.80828C17.8202 2.75076 17.9103 2.72268 18.0011 2.72953L20.5636 2.92453C20.6117 2.92798 20.6586 2.94109 20.7017 2.96312C20.7449 2.98515 20.7833 3.01567 20.8149 3.05292C20.8466 3.09018 20.8707 3.13346 20.886 3.18027C20.9013 3.22709 20.9074 3.27653 20.904 3.32578C20.9007 3.37502 20.8879 3.42311 20.8664 3.46729C20.8449 3.51146 20.8151 3.55087 20.7787 3.58326C20.7423 3.61564 20.7001 3.64037 20.6544 3.65603C20.6087 3.67169 20.5604 3.67798 20.5123 3.67453L18.0926 3.49078L15.4569 5.64328C15.393 5.69756 15.3129 5.72798 15.2299 5.72953Z"
      fill="currentColor"
    />
    <path
      d="M19.6221 4.97945C19.5561 4.9783 19.4916 4.95886 19.4354 4.9232L17.4769 3.7232C17.4036 3.6686 17.3527 3.58809 17.334 3.49725C17.3154 3.40641 17.3302 3.3117 17.3758 3.23144C17.4213 3.15118 17.4943 3.09109 17.5806 3.06278C17.6669 3.03447 17.7604 3.03997 17.843 3.0782L19.7978 4.28195C19.8813 4.33268 19.9417 4.41531 19.9657 4.51165C19.9898 4.60799 19.9755 4.71016 19.926 4.7957C19.8945 4.85007 19.8502 4.89543 19.7971 4.92755C19.744 4.95967 19.6838 4.97753 19.6221 4.97945Z"
      fill="currentColor"
    />
    <path
      d="M8.63866 16.0937H7.05357C6.95648 16.0937 6.86337 16.0542 6.79472 15.9839C6.72607 15.9136 6.6875 15.8182 6.6875 15.7187C6.6875 15.6193 6.72607 15.5239 6.79472 15.4536C6.86337 15.3833 6.95648 15.3438 7.05357 15.3438H8.63866C8.73575 15.3438 8.82886 15.3833 8.89751 15.4536C8.96616 15.5239 9.00473 15.6193 9.00473 15.7187C9.00473 15.8182 8.96616 15.9136 8.89751 15.9839C8.82886 16.0542 8.73575 16.0937 8.63866 16.0937Z"
      fill="currentColor"
    />
    <path
      d="M18.2737 16.0937H16.6922C16.5952 16.0937 16.502 16.0542 16.4334 15.9839C16.3647 15.9136 16.3262 15.8182 16.3262 15.7187C16.3262 15.6193 16.3647 15.5239 16.4334 15.4536C16.502 15.3833 16.5952 15.3438 16.6922 15.3438H18.2773C18.3744 15.3438 18.4675 15.3833 18.5362 15.4536C18.6048 15.5239 18.6434 15.6193 18.6434 15.7187C18.6434 15.8182 18.6048 15.9136 18.5362 15.9839C18.4675 16.0542 18.3744 16.0937 18.2773 16.0937H18.2737Z"
      fill="currentColor"
    />
  </svg>
);

export default ServiceIconMotocicletas;
