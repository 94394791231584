import React from "react";

const ServiceIconMotoLed = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g mask="url(#mask0_3427_22310)">
      <path
        d="M16.5299 23.5957C15.6473 23.5957 14.8799 22.8501 14.8799 21.9082C14.8799 21.0056 15.609 20.2207 16.5299 20.2207C17.4509 20.2207 18.18 20.9664 18.18 21.9082C18.1416 22.8501 17.4125 23.5957 16.5299 23.5957ZM16.5299 21.0448C16.0695 21.0448 15.6473 21.4372 15.6473 21.9474C15.6473 22.4184 16.0311 22.8501 16.5299 22.8501C17.0288 22.8501 17.4125 22.4576 17.4125 21.9474C17.3741 21.4372 16.9904 21.0448 16.5299 21.0448Z"
        fill="currentColor"
      />
      <path
        d="M12.3512 23.625C11.4686 23.625 10.7012 22.8794 10.7012 21.9375C10.7012 21.0349 11.4303 20.25 12.3512 20.25C13.2338 20.25 14.0013 20.9956 14.0013 21.9375C14.0013 22.8794 13.2722 23.625 12.3512 23.625ZM12.3512 21.0741C11.8907 21.0741 11.4686 21.4665 11.4686 21.9767C11.4686 22.4477 11.8524 22.8794 12.3512 22.8794C12.8501 22.8794 13.2338 22.4869 13.2338 21.9767C13.2338 21.4665 12.8501 21.0741 12.3512 21.0741Z"
        fill="currentColor"
      />
      <path
        d="M20.6024 23.625C19.7213 23.625 18.9551 22.8794 18.9551 21.9375C18.9551 21.0349 19.683 20.25 20.6024 20.25C21.4835 20.25 22.2497 20.9956 22.2497 21.9375C22.2114 22.8794 21.4835 23.625 20.6024 23.625ZM20.6024 21.0741C20.1427 21.0741 19.7213 21.4665 19.7213 21.9767C19.7213 22.4477 20.1044 22.8794 20.6024 22.8794C21.1004 22.8794 21.4835 22.4869 21.4835 21.9767C21.4452 21.4665 21.0621 21.0741 20.6024 21.0741Z"
        fill="currentColor"
      />
      <path
        d="M20.6024 13.502C19.7213 13.502 18.9551 12.7563 18.9551 11.8145C18.9551 10.9118 19.683 10.127 20.6024 10.127C21.4835 10.127 22.2497 10.8726 22.2497 11.8145C22.2114 12.7563 21.4835 13.502 20.6024 13.502ZM20.6024 10.9511C20.1427 10.9511 19.7213 11.3435 19.7213 11.8537C19.7213 12.3246 20.1044 12.7563 20.6024 12.7563C21.1004 12.7563 21.4835 12.3639 21.4835 11.8537C21.4452 11.3435 21.0621 10.9511 20.6024 10.9511Z"
        fill="currentColor"
      />
      <path
        d="M16.5751 13.502C15.6939 13.502 14.9277 12.7563 14.9277 11.8145C14.9277 10.9118 15.6556 10.127 16.5751 10.127C17.4562 10.127 18.2224 10.8726 18.2224 11.8145C18.1841 12.7563 17.4562 13.502 16.5751 13.502ZM16.5751 10.9511C16.1153 10.9511 15.6939 11.3435 15.6939 11.8537C15.6939 12.3246 16.077 12.7563 16.5751 12.7563C17.0731 12.7563 17.4562 12.3639 17.4562 11.8537C17.4179 11.3435 17.0348 10.9511 16.5751 10.9511Z"
        fill="currentColor"
      />
      <path
        d="M12.1835 13.502C11.3023 13.502 10.5361 12.7563 10.5361 11.8145C10.5361 10.9118 11.264 10.127 12.1835 10.127C13.0646 10.127 13.8308 10.8726 13.8308 11.8145C13.7925 12.7563 13.0646 13.502 12.1835 13.502ZM12.1835 10.9511C11.7237 10.9511 11.3023 11.3435 11.3023 11.8537C11.3023 12.3246 11.6854 12.7563 12.1835 12.7563C12.6815 12.7563 13.0646 12.3639 13.0646 11.8537C13.0263 11.3435 12.6432 10.9511 12.1835 10.9511Z"
        fill="currentColor"
      />
      <path
        d="M16.9557 13.3242H16.1465V20.6069H16.9557V13.3242Z"
        fill="currentColor"
      />
      <path
        d="M12.7487 13.3242H11.9395V20.6069H12.7487V13.3242Z"
        fill="currentColor"
      />
      <path
        d="M20.7598 17.2569H12.3441C12.1013 17.2569 11.9395 17.0914 11.9395 16.8431C11.9395 16.5949 12.1013 16.4294 12.3441 16.4294H20.3148V13.3259C20.3148 13.0776 20.4766 12.9121 20.7194 12.9121C20.9622 12.9121 21.124 13.0776 21.124 13.3259V16.8431C21.1645 17.05 20.9621 17.2569 20.7598 17.2569Z"
        fill="currentColor"
      />
      <path
        d="M8.49926 10.5943C9.23665 10.5943 9.83446 9.98294 9.83446 9.2288C9.83446 8.47466 9.23665 7.86328 8.49926 7.86328C7.76187 7.86328 7.16406 8.47466 7.16406 9.2288C7.16406 9.98294 7.76187 10.5943 8.49926 10.5943Z"
        fill="currentColor"
      />
      <path
        d="M8.50105 16.1792C8.1369 16.1792 7.77276 16.1378 7.36815 16.0551C7.16585 16.0137 7.00401 15.8068 7.04447 15.5999C7.08493 15.3516 7.08493 15.1034 7.08493 14.8551C7.08493 11.9999 4.90006 9.59991 2.10829 9.39301C1.90598 9.39301 1.74414 9.18611 1.74414 8.97922C1.7846 8.19301 1.94644 7.4068 2.18921 6.70335C2.27013 6.53784 2.39151 6.45508 2.55335 6.45508H14.4487C14.6106 6.45508 14.7724 6.57922 14.8129 6.70335C15.0961 7.44818 15.258 8.19301 15.258 9.0206C15.258 9.22749 15.0961 9.43439 14.8938 9.47577C14.3678 9.55853 13.8418 9.72404 13.3563 9.93094C13.154 10.0137 12.9112 9.93094 12.8303 9.72404C12.7494 9.51715 12.8303 9.26887 13.0326 9.18611C13.4777 8.97922 13.9632 8.8137 14.4487 8.73094C14.4083 8.23439 14.3274 7.77922 14.1655 7.32404H2.87704C2.71519 7.77922 2.63427 8.23439 2.59381 8.68956C5.58789 9.10335 7.89414 11.793 7.89414 14.9378C7.89414 15.062 7.89414 15.2275 7.89414 15.3516C8.46059 15.4344 8.98657 15.393 9.51256 15.3103C9.51256 15.1861 9.51256 15.062 9.51256 14.9378C9.51256 14.3171 9.59348 13.7378 9.75532 13.1585C9.83624 12.9516 10.0385 12.8275 10.2409 12.8689C10.4432 12.9516 10.5645 13.1585 10.5241 13.3654C10.3622 13.862 10.2813 14.3999 10.2813 14.9378C10.2813 15.1447 10.2813 15.393 10.3218 15.5999C10.3622 15.8068 10.2004 16.0137 9.99809 16.0551C9.51256 16.0965 8.98657 16.1792 8.50105 16.1792Z"
        fill="currentColor"
      />
      <path
        d="M8.50105 16.1804C4.77868 16.1804 1.74414 13.077 1.74414 9.27008C1.74414 5.46319 4.77868 2.31836 8.50105 2.31836C12.2234 2.31836 15.258 5.42181 15.258 9.22871C15.258 9.4356 15.258 9.6425 15.2175 9.80802C15.2175 10.0149 15.0152 10.2218 14.7724 10.1804C14.5297 10.1804 14.3678 9.97353 14.4083 9.72526C14.4083 9.55974 14.4487 9.39422 14.4487 9.22871C14.4487 5.87698 11.7783 3.14595 8.50105 3.14595C5.22374 3.14595 2.55335 5.87698 2.55335 9.22871C2.55335 12.5804 5.22374 15.3115 8.50105 15.3115C9.2698 15.3115 10.0385 15.1459 10.7668 14.8563C10.9691 14.7735 11.2119 14.8563 11.2928 15.0632C11.3737 15.2701 11.2928 15.5184 11.0905 15.6011C10.2813 15.9735 9.39118 16.1804 8.50105 16.1804Z"
        fill="currentColor"
      />
      <path
        d="M8.49962 17.6695C3.96804 17.6695 0.286133 13.8626 0.286133 9.22812C0.286133 4.59364 3.96804 0.828125 8.49962 0.828125C13.0312 0.828125 16.7131 4.59364 16.7131 9.22812C16.7131 9.4764 16.5513 9.64192 16.3085 9.64192C16.0657 9.64192 15.9039 9.4764 15.9039 9.22812C15.9444 5.04881 12.5861 1.65571 8.49962 1.65571C4.41311 1.65571 1.09534 5.04881 1.09534 9.22812C1.09534 13.4074 4.41311 16.8005 8.49962 16.8005C9.30883 16.8005 10.0776 16.6764 10.8463 16.4281C11.0486 16.3454 11.2914 16.4695 11.3723 16.6764C11.4532 16.8833 11.3319 17.1316 11.1296 17.2143C10.2799 17.504 9.38975 17.6695 8.49962 17.6695Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default ServiceIconMotoLed;
