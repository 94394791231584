import React from "react";

const ServiceIconSistemaElectrico = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8192 19.125H3.51562V6.75H21.8192V19.125ZM4.24777 18.375H21.0871V7.5H4.24777V18.375Z"
      fill="currentColor"
    />
    <path
      d="M7.1769 5.625H5.34654C5.24945 5.625 5.15634 5.58549 5.08769 5.51516C5.01904 5.44484 4.98047 5.34946 4.98047 5.25V3.375C4.98047 3.27554 5.01904 3.18016 5.08769 3.10984C5.15634 3.03951 5.24945 3 5.34654 3H7.1769C7.27399 3 7.3671 3.03951 7.43575 3.10984C7.5044 3.18016 7.54297 3.27554 7.54297 3.375V5.25C7.54297 5.34946 7.5044 5.44484 7.43575 5.51516C7.3671 5.58549 7.27399 5.625 7.1769 5.625ZM5.71261 4.875H6.81083V3.75H5.71261V4.875Z"
      fill="currentColor"
    />
    <path
      d="M19.9874 5.625H18.1571C18.06 5.625 17.9669 5.58549 17.8982 5.51516C17.8296 5.44484 17.791 5.34946 17.791 5.25V3.375C17.791 3.27554 17.8296 3.18016 17.8982 3.10984C17.9669 3.03951 18.06 3 18.1571 3H19.9874C20.0845 3 20.1776 3.03951 20.2463 3.10984C20.3149 3.18016 20.3535 3.27554 20.3535 3.375V5.25C20.3535 5.34946 20.3149 5.44484 20.2463 5.51516C20.1776 5.58549 20.0845 5.625 19.9874 5.625ZM18.5232 4.875H19.6214V3.75H18.5232V4.875Z"
      fill="currentColor"
    />
    <path
      d="M22.5509 21H2.78306C2.68598 21 2.59286 20.9605 2.52421 20.8902C2.45556 20.8198 2.41699 20.7245 2.41699 20.625V18.75C2.41699 18.6505 2.45556 18.5552 2.52421 18.4848C2.59286 18.4145 2.68598 18.375 2.78306 18.375H22.5509C22.648 18.375 22.7411 18.4145 22.8098 18.4848C22.8784 18.5552 22.917 18.6505 22.917 18.75V20.625C22.917 20.7245 22.8784 20.8198 22.8098 20.8902C22.7411 20.9605 22.648 21 22.5509 21ZM3.14913 20.25H22.1848V19.125H3.14913V20.25Z"
      fill="currentColor"
    />
    <path
      d="M22.5509 7.5H2.78306C2.68598 7.5 2.59286 7.46049 2.52421 7.39017C2.45556 7.31984 2.41699 7.22446 2.41699 7.125V5.25C2.41699 5.15054 2.45556 5.05516 2.52421 4.98484C2.59286 4.91451 2.68598 4.875 2.78306 4.875H22.5509C22.648 4.875 22.7411 4.91451 22.8098 4.98484C22.8784 5.05516 22.917 5.15054 22.917 5.25V7.125C22.917 7.22446 22.8784 7.31984 22.8098 7.39017C22.7411 7.46049 22.648 7.5 22.5509 7.5ZM3.14913 6.75H22.1848V5.625H3.14913V6.75Z"
      fill="currentColor"
    />
    <g clipPath="url(#clip0_3427_22331)">
      <path
        d="M12.4455 16.6875C12.4089 16.6875 12.3723 16.6875 12.2991 16.65C12.1526 16.575 12.0428 16.425 12.0794 16.275L12.2991 14.175H9.73656C9.59013 14.175 9.48031 14.1 9.4071 13.95C9.33388 13.8375 9.37049 13.65 9.4437 13.5375L12.6651 9.525C12.775 9.375 12.958 9.3375 13.1044 9.4125C13.2508 9.4875 13.3607 9.6375 13.3241 9.7875L13.1044 11.9625H15.3741C15.5205 11.9625 15.6303 12.0375 15.7035 12.1875C15.7767 12.3 15.7401 12.45 15.6669 12.5625L12.7383 16.5375C12.6651 16.65 12.5553 16.6875 12.4455 16.6875ZM10.5053 13.425H12.7017C12.8116 13.425 12.9214 13.4625 12.958 13.5375C13.0312 13.6125 13.0678 13.725 13.0678 13.8375L12.958 15L14.6419 12.7125H12.7017C12.5919 12.7125 12.4821 12.675 12.4455 12.6C12.3723 12.525 12.3357 12.4125 12.3357 12.3L12.4821 10.95L10.5053 13.425Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3427_22331">
        <rect
          width="6.36964"
          height="7.3125"
          fill="transparent"
          transform="translate(9.37109 9.375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ServiceIconSistemaElectrico;
